export const currentUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://serviceapi.myorigin.net'
    : 'http://localhost:4001';
export const wsUrl =
  process.env.NODE_ENV === 'production'
    ? 'wss://serviceapi.myorigin.net'
    : 'ws://localhost:4001';

// export const currentUrl =
//   process.env.NODE_ENV === 'production'
//     ? 'https://serviceapi.myorigin.net'
//     : 'https://serviceapi.myorigin.icu';
// export const wsUrl =
//   process.env.NODE_ENV === 'production'
//     ? 'wss://serviceapi.myorigin.net'
//     : 'wss://serviceapi.myorigin.icu';

// export const mainUrl =
//   process.env.NODE_ENV === 'production'
//     ? 'https://api.myorigin.net'
//     : 'http://localhost:3001';

export const mainUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api.myorigin.net'
    : 'https://api.myorigin.net';

export default currentUrl;
